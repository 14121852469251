import React from 'react'
import { Text } from '@/app/atoms/text/text'
import { Link, PageProps } from 'gatsby'
import { Button } from '@/app/atoms/button/button'
import { css } from '@emotion/core'
import { SEO } from '@/app/organisms/layout/components/seo'

const NotFound: React.FC<PageProps> = () => (
  <>
    <SEO pageTitle="Page Not Found" />
    <main
      css={css`
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <Text type="subTitle">Sorry... Page not found</Text>
      <Link
        to="/"
        css={css`
          padding: 32px;
        `}
      >
        <Button type="button" size="medium">
          Return to Home
        </Button>
      </Link>
    </main>
  </>
)

export default NotFound
